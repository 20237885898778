import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export default function ArticleTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7">
              <div className="mb-5 mt-6">
                <h1 className="title is-size-1 is-family-secondary has-text-weight-normal">
                  <span>{frontmatter.title}</span>
                </h1>
                <p className="has-text-grey is-italic">{frontmatter.date}</p>
              </div>
              <div
                className="content is-size-5"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        slug
        title
        cover
      }
    }
  }
`
