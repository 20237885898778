import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const DesktopHeader = ({ siteTitle }) => (
  <div className="mb-4">
    <div className="hero is-small">
      <div className="hero-body has-text-centered">
        <Link to="/">
          <h1
            className="title is-family-handwrite"
            style={{ fontSize: "3.25rem" }}
          >
            {siteTitle}
          </h1>
        </Link>
      </div>
    </div>
    <nav className="" role="navigation" aria-label="main navigation">
      <div className="container">
        <hr className="is-marginless has-background-light" />
        <div
          className="is-flex is-family-secondary"
          style={{ justifyContent: "center" }}
        >
          <div className="lol">
            <Link
              to="/articles/"
              className="has-text-primary is-size-6 px-5 py-2 is-inline-block has-text-weight-bold"
            >
              Publicações
            </Link>
          </div>
          <div className="lol">
            <Link
              to="/articles/"
              className="has-text-primary is-size-6 px-5 py-2 is-inline-block has-text-weight-bold"
            >
              Receitas
            </Link>
          </div>
          <div className="lol">
            <Link
              to="/articles/"
              className="has-text-primary is-size-6 px-5 py-2 is-inline-block has-text-weight-bold"
            >
              Mindf*da-se
            </Link>
          </div>
          <div className="lol">
            <Link
              to="/articles/"
              className="has-text-primary is-size-6 px-5 py-2 is-inline-block has-text-weight-bold"
            >
              Coisas de Mãe
            </Link>
          </div>
          <div className="lol">
            <Link
              to="/articles/"
              className="has-text-primary is-size-6 px-5 py-2 is-inline-block has-text-weight-bold"
            >
              Wonderland
            </Link>
          </div>
        </div>
      </div>
    </nav>
  </div>
)

const MobileHeader = ({ siteTitle }) => (
  <div>
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item is-size-4" href="/">
          <span className="is-family-handwrite">{siteTitle}</span>
        </a>

        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </nav>
  </div>
)

const Header = ({ siteTitle }) => (
  <header>
    <div className="is-hidden-touch">
      <DesktopHeader siteTitle={siteTitle} />
    </div>
    <div className="is-hidden-desktop">
      <MobileHeader siteTitle={siteTitle} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
